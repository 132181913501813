
import {defineComponent} from "vue";
import InfoBox from "../../components/base/InfoBox.vue";
import RouterNavigate from "../../components/base/common/RouterNavigate.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";
import OptOut from "@/components/base/OptOut.vue";
import {useQuickActionStore} from "@/store/quick.action.store";

export default defineComponent({
  name: "WorkLayout",
  components: {OptOut, HelpEmbed, RouterNavigate, InfoBox},
  setup() {
    const quickActionStore = useQuickActionStore()
    return{
      quickActionStore,
    }
  },
  methods:{

  }
})
