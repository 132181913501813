import {defineStore} from "pinia";

export const useQuickActionStore = defineStore('quickActionStore', {
  state: () => ({
    quickAction: <any>{entity: ''}
  }),
  actions: {
    setAction(entity) {
      this.quickAction.entity = entity;
    }
  }
})